import React from "react";
import { IoHeart } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";

import * as Styled from "./index.styled";

export function Footer() {
  return (
    <footer>
      <Styled.Text>
        
      Webkwaster en hondwerper: <Styled.Link href="https://anthe.sevenants.net">
          Anthe Sevenants
        </Styled.Link><br></br>
        Niet verbonden aan Studio 100.
      </Styled.Text>
    </footer>
  );
}
