export const songs = [
    {
        "name": "Er Zit Meer In Een Liedje",
        "artist": "Samson & Gert",
        "youtubeId": "fvUENN6aGhE",
        "name_l": "er zit meer in een liedje"
    },
    {
        "name": "Vrede",
        "artist": "Samson & Gert",
        "youtubeId": "zvfjfpS_bY8",
        "name_l": "vrede"
    },
    {
        "name": "Spaghetti",
        "artist": "Samson & Gert",
        "youtubeId": "IOzwCwyG7Ck",
        "name_l": "spaghetti"
    },
    {
        "name": "Vrolijk liedje",
        "artist": "Samson & Gert",
        "youtubeId": "l31oM1y2fbM",
        "name_l": "vrolijk liedje"
    },
    {
        "name": "Ver ver ver verliefd",
        "artist": "Samson & Gert",
        "youtubeId": "O8Xt001BL14",
        "name_l": "ver ver ver verliefd"
    },
    {
        "name": "Rinkelbel",
        "artist": "Samson & Gert",
        "youtubeId": "-XaeGqvqCzs",
        "name_l": "rinkelbel"
    },
    {
        "name": "Naar zee",
        "artist": "Samson & Gert",
        "youtubeId": "bv4XFJ_xJIk",
        "name_l": "naar zee"
    },
    {
        "name": "Samson de Kersthond",
        "artist": "Samson & Gert",
        "youtubeId": "OrZHJ0yRCk0",
        "name_l": "samson de kersthond"
    },
    {
        "name": "T Is Een Kwis",
        "artist": "Samson & Gert",
        "youtubeId": "206X3hYRP6Q",
        "name_l": "t is een kwis"
    },
    {
        "name": "Vrijdag De Dertiende",
        "artist": "Samson & Gert",
        "youtubeId": "5iXnXoqn1jA",
        "name_l": "vrijdag de dertiende"
    },
    {
        "name": "Alles Is Op",
        "artist": "Samson & Gert",
        "youtubeId": "fFDPCKH_jNU",
        "name_l": "alles is op"
    },
    {
        "name": "Slaapliedje",
        "artist": "Samson & Gert",
        "youtubeId": "NI-lMduL5AE",
        "name_l": "slaapliedje"
    },
    {
        "name": "Vrede op aarde",
        "artist": "Samson & Gert",
        "youtubeId": "Ae_HeeLXJB0",
        "name_l": "vrede op aarde"
    },
    {
        "name": "Op Het Dak",
        "artist": "Samson & Gert",
        "youtubeId": "B2mlBpP6_tM",
        "name_l": "op het dak"
    },
    {
        "name": "Amerika",
        "artist": "Samson & Gert",
        "youtubeId": "JgXUT-XkREU",
        "name_l": "amerika"
    },
    {
        "name": "Vrienden",
        "artist": "Samson & Gert",
        "youtubeId": "Whh00l0UbdM",
        "name_l": "vrienden"
    },
    {
        "name": "Roeien",
        "artist": "Samson & Gert",
        "youtubeId": "_9WwL_7ZxqM",
        "name_l": "roeien"
    },
    {
        "name": "Springen in de zee",
        "artist": "Samson & Gert",
        "youtubeId": "LLPZFYcU9bA",
        "name_l": "springen in de zee"
    },
    {
        "name": "T Is Afgelopen",
        "artist": "Samson & Gert",
        "youtubeId": "T95RGczcGTw",
        "name_l": "t is afgelopen"
    },
    {
        "name": "Bowlen",
        "artist": "Samson & Gert",
        "youtubeId": "DqySHAOsbMg",
        "name_l": "bowlen"
    },
    {
        "name": "De Samson-Samba",
        "artist": "Samson & Gert",
        "youtubeId": "ScycE6f4Ql8",
        "name_l": "de samson-samba"
    },
    {
        "name": "Lieve Kerstman",
        "artist": "Samson & Gert",
        "youtubeId": "tsxRZ2zEg74",
        "name_l": "lieve kerstman"
    },
    {
        "name": "Bim Bam Bom",
        "artist": "Samson & Gert",
        "youtubeId": "gYyRBFpnNbs",
        "name_l": "bim bam bom"
    },
    {
        "name": "Supergert & Supersamson",
        "artist": "Samson & Gert",
        "youtubeId": "j-0wKCFNcBg",
        "name_l": "supergert & supersamson"
    },
    {
        "name": "Kerst voor iedereen",
        "artist": "Samson & Gert",
        "youtubeId": "CouR4tIu0r8",
        "name_l": "kerst voor iedereen"
    },
    {
        "name": "Samsonlied",
        "artist": "Samson & Gert",
        "youtubeId": "G6ii1dZuR60",
        "name_l": "samsonlied"
    },
    {
        "name": "De Opera",
        "artist": "Samson & Gert",
        "youtubeId": "mGz3DWR406s",
        "name_l": "de opera"
    },
    {
        "name": "Hatsjie",
        "artist": "Samson & Gert",
        "youtubeId": "f_zphMIcfSM",
        "name_l": "hatsjie"
    },
    {
        "name": "Verjaardag",
        "artist": "Samson & Gert",
        "youtubeId": "ja26uAvH0IE",
        "name_l": "verjaardag"
    },
    {
        "name": "Ik Ben Zo Blij",
        "artist": "Samson & Gert",
        "youtubeId": "HCozfG3hZ9E",
        "name_l": "ik ben zo blij"
    },
    {
        "name": "Robin Hood",
        "artist": "Samson & Gert",
        "youtubeId": "suu_ZGRtYW8",
        "name_l": "robin hood"
    },
    {
        "name": "Vrienden voor het leven",
        "artist": "Samson & Gert",
        "youtubeId": "5foX8q24rcI",
        "name_l": "vrienden voor het leven"
    },
    {
        "name": "Kei kei keileuke zomer",
        "artist": "Samson & Gert",
        "youtubeId": "cfB_jRNQUf0",
        "name_l": "kei kei keileuke zomer"
    },
    {
        "name": "Yeah Yeah Yeah",
        "artist": "Samson & Gert",
        "youtubeId": "wM_DDhpv7rY",
        "name_l": "yeah yeah yeah"
    },
    {
        "name": "Wij Zijn Rijk",
        "artist": "Samson & Gert",
        "youtubeId": "1uZg2lOE2II",
        "name_l": "wij zijn rijk"
    },
    {
        "name": "Samson Toch!",
        "artist": "Samson & Gert",
        "youtubeId": "tOjBY8Xg5cM",
        "name_l": "samson toch!"
    },
    {
        "name": "De Snijdersbank",
        "artist": "Samson & Gert",
        "youtubeId": "2o570KoeNQs",
        "name_l": "de snijdersbank"
    },
    {
        "name": "10 Miljoen",
        "artist": "Samson & Gert",
        "youtubeId": "LHpgOauR4dw",
        "name_l": "10 miljoen"
    },
    {
        "name": "Kapitein",
        "artist": "Samson & Gert",
        "youtubeId": "hRnYiIxr5-M",
        "name_l": "kapitein"
    },
    {
        "name": "Koud",
        "artist": "Samson & Gert",
        "youtubeId": "tPF6Gq0YGTI",
        "name_l": "koud"
    },
    {
        "name": "Zigge Zagge Zomer",
        "artist": "Samson & Gert",
        "youtubeId": "Lk2wvNczWKc",
        "name_l": "zigge zagge zomer"
    },
    {
        "name": "Ik Wil Niet In Bad",
        "artist": "Samson & Gert",
        "youtubeId": "kB6KSYeciYk",
        "name_l": "ik wil niet in bad"
    },
    {
        "name": "Wij gaan beginnen",
        "artist": "Samson & Gert",
        "youtubeId": "v69JoUoU5Io",
        "name_l": "wij gaan beginnen"
    },
    {
        "name": "Samen Delen",
        "artist": "Samson & Gert",
        "youtubeId": "FQ1a83xUkMc",
        "name_l": "samen delen"
    },
    {
        "name": "De Trampoline",
        "artist": "Samson & Gert",
        "youtubeId": "UnlHAKYok_I",
        "name_l": "de trampoline"
    },
    {
        "name": "Piraten-potpourri",
        "artist": "Samson & Gert",
        "youtubeId": "puXBTOPs11Q",
        "name_l": "piraten-potpourri"
    },
    {
        "name": "Handen hoog",
        "artist": "Samson & Gert",
        "youtubeId": "BBsmOuJijTE",
        "name_l": "handen hoog"
    },
    {
        "name": "Tingelingeling",
        "artist": "Samson & Gert",
        "youtubeId": "snzGUS3YXMA",
        "name_l": "tingelingeling"
    },
    {
        "name": "Warm!",
        "artist": "Samson & Gert",
        "youtubeId": "kUocXUXmr8I",
        "name_l": "warm!"
    },
    {
        "name": "Klaar Voor De Start",
        "artist": "Samson & Gert",
        "youtubeId": "QATg4tZZqns",
        "name_l": "klaar voor de start"
    },
    {
        "name": "Feest Vandaag",
        "artist": "Samson & Gert",
        "youtubeId": "zEqwpITQjhA",
        "name_l": "feest vandaag"
    },
    {
        "name": "Muizen",
        "artist": "Samson & Gert",
        "youtubeId": "3oqSzDsS9Gs",
        "name_l": "muizen"
    },
    {
        "name": "Wakker Worden",
        "artist": "Samson & Gert",
        "youtubeId": "__RaCSmFKxI",
        "name_l": "wakker worden"
    },
    {
        "name": "De Mooiste Dromen",
        "artist": "Samson & Gert",
        "youtubeId": "2eTjFu78fhY",
        "name_l": "de mooiste dromen"
    },
    {
        "name": "Feest In De Straat",
        "artist": "Samson & Gert",
        "youtubeId": "KcNAtvzMU00",
        "name_l": "feest in de straat"
    },
    {
        "name": "De Fanfare",
        "artist": "Samson & Gert",
        "youtubeId": "-mH6VVLOFPY",
        "name_l": "de fanfare"
    },
    {
        "name": "De Mooiste Dag",
        "artist": "Samson & Gert",
        "youtubeId": "3WbwHTSILpI",
        "name_l": "de mooiste dag"
    },
    {
        "name": "Aap In Huis",
        "artist": "Samson & Gert",
        "youtubeId": "6PKPtna-S6Y",
        "name_l": "aap in huis"
    },
    {
        "name": "Wij Gaan Naar De Maan",
        "artist": "Samson & Gert",
        "youtubeId": "Km_KoopswOA",
        "name_l": "wij gaan naar de maan"
    },
    {
        "name": "In De Disco",
        "artist": "Samson & Gert",
        "youtubeId": "j54IJ_m9ktg",
        "name_l": "in de disco"
    },
    {
        "name": "Feestje Voor De Wereld",
        "artist": "Samson & Gert",
        "youtubeId": "jz1DOGitA1I",
        "name_l": "feestje voor de wereld"
    },
    {
        "name": "Krakzo de mattebom",
        "artist": "Samson & Gert",
        "youtubeId": "pvLeIPHUwCc",
        "name_l": "krakzo de mattebom"
    },
    {
        "name": "Zondag",
        "artist": "Samson & Gert",
        "youtubeId": "MuVW4Gh2mJ0",
        "name_l": "zondag"
    },
    {
        "name": "Wij Goochelen",
        "artist": "Samson & Gert",
        "youtubeId": "c4sHUsBcoQY",
        "name_l": "wij goochelen"
    },
    {
        "name": "Neefje Kwik",
        "artist": "Samson & Gert",
        "youtubeId": "qvxV75Y9pJM",
        "name_l": "neefje kwik"
    },
    {
        "name": "De Jongens en de Meisjes",
        "artist": "Samson & Gert",
        "youtubeId": "xPaAZqK0WFE",
        "name_l": "de jongens en de meisjes"
    },
    {
        "name": "S.O.S.",
        "artist": "Samson & Gert",
        "youtubeId": "moAl0sNwZi4",
        "name_l": "s.o.s."
    },
    {
        "name": "Sinterklaas en Zwarte Piet",
        "artist": "Samson & Gert",
        "youtubeId": "AwmeaZS98e0",
        "name_l": "sinterklaas en zwarte piet"
    },
    {
        "name": "Radio",
        "artist": "Samson & Gert",
        "youtubeId": "w3vnhPzY80U",
        "name_l": "radio"
    },
    {
        "name": "Dromedaris",
        "artist": "Samson & Gert",
        "youtubeId": "ugNn3zqSWlk",
        "name_l": "dromedaris"
    },
    {
        "name": "Als Je De Samson Doet",
        "artist": "Samson & Gert",
        "youtubeId": "Q0Wz6RJCc54",
        "name_l": "als je de samson doet"
    },
    {
        "name": "Vrolijke vrienden",
        "artist": "Samson & Gert",
        "youtubeId": "e3wJKaqENOA",
        "name_l": "vrolijke vrienden"
    },
    {
        "name": "Het Dierenkoor",
        "artist": "Samson & Gert",
        "youtubeId": "2vN0wykOgwA",
        "name_l": "het dierenkoor"
    },
    {
        "name": "Ochtendgymnastiek",
        "artist": "Samson & Gert",
        "youtubeId": "H5bdXxJ1wP8",
        "name_l": "ochtendgymnastiek"
    },
    {
        "name": "Ol\u00e9 Pistole",
        "artist": "Samson & Gert",
        "youtubeId": "tp6T2Rcia9s",
        "name_l": "ol\u00e9 pistole"
    },
    {
        "name": "Hard Rock",
        "artist": "Samson & Gert",
        "youtubeId": "qQ446FJvLlk",
        "name_l": "hard rock"
    },
    {
        "name": "Hiep Hiep Hiep Hoera!",
        "artist": "Samson & Gert",
        "youtubeId": "SNoHkY_v6Tg",
        "name_l": "hiep hiep hiep hoera!"
    },
    {
        "name": "De Hik",
        "artist": "Samson & Gert",
        "youtubeId": "nfmW8G8u8KA",
        "name_l": "de hik"
    },
    {
        "name": "Bobientje Gaat Verhuizen",
        "artist": "Samson & Gert",
        "youtubeId": "oM9TkGrs068",
        "name_l": "bobientje gaat verhuizen"
    },
    {
        "name": "De Duikboot",
        "artist": "Samson & Gert",
        "youtubeId": "wIJxlOB9RhE",
        "name_l": "de duikboot"
    },
    {
        "name": "Kermis",
        "artist": "Samson & Gert",
        "youtubeId": "hUOaA0Hb96U",
        "name_l": "kermis"
    },
    {
        "name": "Repeteren",
        "artist": "Samson & Gert",
        "youtubeId": "pVlXC5TV-Bc",
        "name_l": "repeteren"
    },
    {
        "name": "Als Je Bang Bent",
        "artist": "Samson & Gert",
        "youtubeId": "1618jA0fiQo",
        "name_l": "als je bang bent"
    },
    {
        "name": "Jimmy De Cowboy",
        "artist": "Samson & Gert",
        "youtubeId": "ogU-GactcoQ",
        "name_l": "jimmy de cowboy"
    },
    {
        "name": "De Wereld Is Mooi",
        "artist": "Samson & Gert",
        "youtubeId": "aGln8-15At4",
        "name_l": "de wereld is mooi"
    },
    {
        "name": "Samson is a DJ",
        "artist": "Samson & Gert",
        "youtubeId": "v0TG9hVD9RQ",
        "name_l": "samson is a dj"
    },
    {
        "name": "Niet Ver Weg",
        "artist": "Samson & Gert",
        "youtubeId": "5VCbrx6GUng",
        "name_l": "niet ver weg"
    },
    {
        "name": "De Telefoon",
        "artist": "Samson & Gert",
        "youtubeId": "bW5GfMVd_LU",
        "name_l": "de telefoon"
    },
    {
        "name": "Verliefd Zijn",
        "artist": "Samson & Gert",
        "youtubeId": "Jp4DEKvnBLo",
        "name_l": "verliefd zijn"
    },
    {
        "name": "Oh La La La!",
        "artist": "Samson & Gert",
        "youtubeId": "69eH2gkfVCI",
        "name_l": "oh la la la!"
    },
    {
        "name": "Samsonrock",
        "artist": "Samson & Gert",
        "youtubeId": "TA6-rSr7x2k",
        "name_l": "samsonrock"
    },
    {
        "name": "Samen Op De Moto",
        "artist": "Samson & Gert",
        "youtubeId": "RtaAqzueCV8",
        "name_l": "samen op de moto"
    },
    {
        "name": "Ik Moet Plassen",
        "artist": "Samson & Gert",
        "youtubeId": "P__dgYkBnQ4",
        "name_l": "ik moet plassen"
    },
    {
        "name": "Beste Vriend Op Aarde",
        "artist": "Samson & Gert",
        "youtubeId": "ZqBqgMWNTcE",
        "name_l": "beste vriend op aarde"
    },
    {
        "name": "Kerstmis Kerstmis",
        "artist": "Samson & Gert",
        "youtubeId": "FgBGXFM7LQE",
        "name_l": "kerstmis kerstmis"
    },
    {
        "name": "Wat Ben Ik Blij",
        "artist": "Samson & Gert",
        "youtubeId": "ZPJ9POKPAkY",
        "name_l": "wat ben ik blij"
    },
    {
        "name": "GekkeBekkenTrekken",
        "artist": "Samson & Gert",
        "youtubeId": "qHZDNQ2nLMQ",
        "name_l": "gekkebekkentrekken"
    },
    {
        "name": "Samson rui",
        "artist": "Samson & Gert",
        "youtubeId": "ZDKTUECMcaw",
        "name_l": "samson rui"
    },
    {
        "name": "Jiepie-Ja-Hee",
        "artist": "Samson & Gert",
        "youtubeId": "7NP23ewA1TY",
        "name_l": "jiepie-ja-hee"
    },
    {
        "name": "Patatjes",
        "artist": "Samson & Gert",
        "youtubeId": "pjBhvu52HPo",
        "name_l": "patatjes"
    },
    {
        "name": "Je Neus, Je Neus",
        "artist": "Samson & Gert",
        "youtubeId": "Y63fEdS64LU",
        "name_l": "je neus, je neus"
    },
    {
        "name": "Vooruit",
        "artist": "Samson & Gert",
        "youtubeId": "3f-jbLKsnY8",
        "name_l": "vooruit"
    },
    {
        "name": "De Baby",
        "artist": "Samson & Gert",
        "youtubeId": "0VhNMnUS-i0",
        "name_l": "de baby"
    },
    {
        "name": "Ding Dong",
        "artist": "Samson & Gert",
        "youtubeId": "RXFrONpALrc",
        "name_l": "ding dong"
    },
    {
        "name": "De bel doet 't niet",
        "artist": "Samson & Gert",
        "youtubeId": "gd89sL4gSeY",
        "name_l": "de bel doet 't niet"
    },
    {
        "name": "Marlene",
        "artist": "Samson & Gert",
        "youtubeId": "OKHIrOzh4ck",
        "name_l": "marlene"
    },
    {
        "name": "Samson City",
        "artist": "Samson & Gert",
        "youtubeId": "mPOB_BsZjlw",
        "name_l": "samson city"
    },
    {
        "name": "Vissen",
        "artist": "Samson & Gert",
        "youtubeId": "2FEiRAmKdE0",
        "name_l": "vissen"
    },
    {
        "name": "Marileen En Alexander",
        "artist": "Samson & Gert",
        "youtubeId": "ES1uJFmQlKg",
        "name_l": "marileen en alexander"
    },
    {
        "name": "Zangles",
        "artist": "Samson & Gert",
        "youtubeId": "Az7ZVNoNFbw",
        "name_l": "zangles"
    },
    {
        "name": "Helemaal Van Slag",
        "artist": "Samson & Gert",
        "youtubeId": "2Y4mURMZWWE",
        "name_l": "helemaal van slag"
    },
    {
        "name": "Wij Willen Voetballen",
        "artist": "Samson & Gert",
        "youtubeId": "P8nciiTzUe4",
        "name_l": "wij willen voetballen"
    },
    {
        "name": "Storm Op Zee",
        "artist": "Samson & Gert",
        "youtubeId": "jeq54ErDaTE",
        "name_l": "storm op zee"
    },
    {
        "name": "De Pruikentijd",
        "artist": "Samson & Gert",
        "youtubeId": "8P75s8w6nts",
        "name_l": "de pruikentijd"
    },
    {
        "name": "Wij Komen Naar Jou",
        "artist": "Samson & Gert",
        "youtubeId": "MvFOgZqJrTY",
        "name_l": "wij komen naar jou"
    },
    {
        "name": "Op De Foto",
        "artist": "Samson & Gert",
        "youtubeId": "TvEwxs3S8Dw",
        "name_l": "op de foto"
    },
    {
        "name": "In Het Oerwoud",
        "artist": "Samson & Gert",
        "youtubeId": "QL2hlxA3KjU",
        "name_l": "in het oerwoud"
    },
    {
        "name": "Lieve Samson",
        "artist": "Samson & Gert",
        "youtubeId": "xeg2vITshJM",
        "name_l": "lieve samson"
    },
    {
        "name": "Dan Is Het Kerstmis",
        "artist": "Samson & Gert",
        "youtubeId": "G3Ilky_Sfvg",
        "name_l": "dan is het kerstmis"
    },
    {
        "name": "Naar Het Circus",
        "artist": "Samson & Gert",
        "youtubeId": "4KpgQTJpRMQ",
        "name_l": "naar het circus"
    },
    {
        "name": "Vliegen",
        "artist": "Samson & Gert",
        "youtubeId": "J3WjhyY4Mok",
        "name_l": "vliegen"
    },
    {
        "name": "De Muziek Van Kerstmis",
        "artist": "Samson & Gert",
        "youtubeId": "vqFn4xVdC2Y",
        "name_l": "de muziek van kerstmis"
    },
    {
        "name": "Beste Vriend",
        "artist": "Samson & Gert",
        "youtubeId": "8_4xmxC9MVU",
        "name_l": "beste vriend"
    },
    {
        "name": "Klap Maar In Je Handen",
        "artist": "Samson & Gert",
        "youtubeId": "OH9LyHudiE0",
        "name_l": "klap maar in je handen"
    },
    {
        "name": "Als Je Heel Erg Veel Verliefd Bent",
        "artist": "Samson & Gert",
        "youtubeId": "gAu8DRyue28",
        "name_l": "als je heel erg veel verliefd bent"
    },
    {
        "name": "Naar Bed",
        "artist": "Samson & Gert",
        "youtubeId": "Gb8SXBeSdTE",
        "name_l": "naar bed"
    },
    {
        "name": "Het allerlaatste liedje",
        "artist": "Samson & Gert",
        "youtubeId": "2IttfWxSzjE",
        "name_l": "het allerlaatste liedje"
    },
    {
        "name": "Samson is ziek",
        "artist": "Samson & Gert",
        "youtubeId": "4zBH0qdiDR4",
        "name_l": "samson is ziek"
    },
    {
        "name": "Daar Is De Zon",
        "artist": "Samson & Gert",
        "youtubeId": "96BOdpt1e3E",
        "name_l": "daar is de zon"
    },
    {
        "name": "Bij Heidi in Tirol",
        "artist": "Samson & Gert",
        "youtubeId": "ZZA5YJm-fFc",
        "name_l": "bij heidi in tirol"
    },
    {
        "name": "De Allerliefste Hond",
        "artist": "Samson & Gert",
        "youtubeId": "0s-ydBZ9L60",
        "name_l": "de allerliefste hond"
    },
    {
        "name": "Wereldberoemd",
        "artist": "Samson & Gert",
        "youtubeId": "UxSoKPRLDYk",
        "name_l": "wereldberoemd"
    },
    {
        "name": "Wij Zijn Bij De Brandweer",
        "artist": "Samson & Gert",
        "youtubeId": "hVPdXbPZGZQ",
        "name_l": "wij zijn bij de brandweer"
    },
    {
        "name": "Mac Samson En Mac Gert",
        "artist": "Samson & Gert",
        "youtubeId": "ieMg3j3CqmQ",
        "name_l": "mac samson en mac gert"
    },
    {
        "name": "Hiep Hiep Hoera Voor De Burgemeester",
        "artist": "Samson & Gert",
        "youtubeId": "O2Abo3HyOec",
        "name_l": "hiep hiep hoera voor de burgemeester"
    },
    {
        "name": "Zwemmen",
        "artist": "Samson & Gert",
        "youtubeId": "imwgOxYyQfA",
        "name_l": "zwemmen"
    },
    {
        "name": "Ik ben verliefd",
        "artist": "Samson & Gert",
        "youtubeId": "O90Ck_hB364",
        "name_l": "ik ben verliefd"
    },
    {
        "name": "Aan Tafel",
        "artist": "Samson & Gert",
        "youtubeId": "CIfw7v5RHIY",
        "name_l": "aan tafel"
    },
    {
        "name": "Wij gaan vliegen",
        "artist": "Samson & Gert",
        "youtubeId": "aRCcYO-xkxo",
        "name_l": "wij gaan vliegen"
    },
    {
        "name": "Ome Jaap",
        "artist": "Samson & Gert",
        "youtubeId": "DbswPxw2xfY",
        "name_l": "ome jaap"
    },
    {
        "name": "Mexico",
        "artist": "Samson & Gert",
        "youtubeId": "ZjENQ6YQzY8",
        "name_l": "mexico"
    },
    {
        "name": "Kijken Naar de Sterren",
        "artist": "Samson & Gert",
        "youtubeId": "dqj4oumEb0s",
        "name_l": "kijken naar de sterren"
    },
    {
        "name": "Hele dikke vrienden",
        "artist": "Samson & Gert",
        "youtubeId": "s06FMcV6tGQ",
        "name_l": "hele dikke vrienden"
    },
    {
        "name": "Welterusten",
        "artist": "Samson & Gert",
        "youtubeId": "bHg5QD0ulEA",
        "name_l": "welterusten"
    },
    {
        "name": "Vandaag is het zo'n dag",
        "artist": "Samson & Gert",
        "youtubeId": "Ip36Q5zodcw",
        "name_l": "vandaag is het zo'n dag"
    },
    {
        "name": "Bij Boer Teun",
        "artist": "Samson & Gert",
        "youtubeId": "XGAyk5WiYTY",
        "name_l": "bij boer teun"
    },
    {
        "name": "Wij Gaan Niet Slapen",
        "artist": "Samson & Gert",
        "youtubeId": "p3RBX5eiJPU",
        "name_l": "wij gaan niet slapen"
    },
    {
        "name": "De Wijde Wereld",
        "artist": "Samson & Gert",
        "youtubeId": "WiOkpxAE5HE",
        "name_l": "de wijde wereld"
    },
    {
        "name": "Alle Meisjes",
        "artist": "Samson & Gert",
        "youtubeId": "5eeF9mByLRU",
        "name_l": "alle meisjes"
    },
    {
        "name": "Mannetjes Van Mars",
        "artist": "Samson & Gert",
        "youtubeId": "8LuAEqqXC0g",
        "name_l": "mannetjes van mars"
    },
    {
        "name": "Ferdinand de olifant",
        "artist": "Samson & Gert",
        "youtubeId": "ukkFHjWsXuI",
        "name_l": "ferdinand de olifant"
    },
    {
        "name": "De Koekoeksklok",
        "artist": "Samson & Gert",
        "youtubeId": "GUE0DXqb6Jg",
        "name_l": "de koekoeksklok"
    },
    {
        "name": "Het Kasteel Van Koning Samson",
        "artist": "Samson & Gert",
        "youtubeId": "qdRjFPJrvtU",
        "name_l": "het kasteel van koning samson"
    },
    {
        "name": "Als Samson Droomt",
        "artist": "Samson & Gert",
        "youtubeId": "0GfGq4FoMYc",
        "name_l": "als samson droomt"
    },
    {
        "name": "We Zijn Op Elkaar",
        "artist": "Samson & Gert",
        "youtubeId": "SZgLnXUmiPg",
        "name_l": "we zijn op elkaar"
    },
    {
        "name": "Zwaaien",
        "artist": "Samson & Gert",
        "youtubeId": "YlN5_ru9HqQ",
        "name_l": "zwaaien"
    },
    {
        "name": "Stefanie Van Boer Teun",
        "artist": "Samson & Gert",
        "youtubeId": "VLuOQ_ssgmM",
        "name_l": "stefanie van boer teun"
    },
    {
        "name": "Piloot",
        "artist": "Samson & Gert",
        "youtubeId": "I5vXrXmKkiI",
        "name_l": "piloot"
    },
    {
        "name": "Ik Ben De Sigaar",
        "artist": "Samson & Gert",
        "youtubeId": "bxIlYHtxexE",
        "name_l": "ik ben de sigaar"
    },
    {
        "name": "Het Spook Van de Opera",
        "artist": "Samson & Gert",
        "youtubeId": "0wV1vdaUJ1c",
        "name_l": "het spook van de opera"
    },
    {
        "name": "Warm",
        "artist": "Samson & Gert",
        "youtubeId": "ETYMSRt_LdU",
        "name_l": "warm"
    },
    {
        "name": "Yankee doodle dandee",
        "artist": "Samson & Gert",
        "youtubeId": "9IPvEiJLpOA",
        "name_l": "yankee doodle dandee"
    },
    {
        "name": "Joebadoebadoe",
        "artist": "Samson & Gert",
        "youtubeId": "RaGY_M4SKIU",
        "name_l": "joebadoebadoe"
    },
    {
        "name": "Octaaf de Klusjesman",
        "artist": "Samson & Gert",
        "youtubeId": "26ZhilMLGQY",
        "name_l": "octaaf de klusjesman"
    },
    {
        "name": "Druk",
        "artist": "Samson & Gert",
        "youtubeId": "Wu1wGG4E4tg",
        "name_l": "druk"
    },
    {
        "name": "Samen Ikke En Jij",
        "artist": "Samson & Gert",
        "youtubeId": "3vN4_avRjlc",
        "name_l": "samen ikke en jij"
    },
    {
        "name": "Au",
        "artist": "Samson & Gert",
        "youtubeId": "B6j1lDMPaJQ",
        "name_l": "au"
    },
    {
        "name": "Samson Is Bang",
        "artist": "Samson & Gert",
        "youtubeId": "ajcPJtK9Wao",
        "name_l": "samson is bang"
    },
    {
        "name": "Wie Gaat Er Mee?",
        "artist": "Samson & Gert",
        "youtubeId": "cFe-GbnYM4E",
        "name_l": "wie gaat er mee?"
    },
    {
        "name": "Cowboys En Indianen",
        "artist": "Samson & Gert",
        "youtubeId": "pbdTnuo0i_I",
        "name_l": "cowboys en indianen"
    },
    {
        "name": "Ski\u00ebn",
        "artist": "Samson & Gert",
        "youtubeId": "k6Xp1GOPW5E",
        "name_l": "ski\u00ebn"
    },
    {
        "name": "Thuis",
        "artist": "Samson & Gert",
        "youtubeId": "-LHJ2pHgZ_0",
        "name_l": "thuis"
    },
    {
        "name": "Gert En Samson",
        "artist": "Samson & Gert",
        "youtubeId": "0IQRr3b3JdM",
        "name_l": "gert en samson"
    },
    {
        "name": "De Stoomlocomotief",
        "artist": "Samson & Gert",
        "youtubeId": "SeUYsTEIwLo",
        "name_l": "de stoomlocomotief"
    },
    {
        "name": "Wij Weten Wat Liefde Is",
        "artist": "Samson & Gert",
        "youtubeId": "aZPd-cHRCkc",
        "name_l": "wij weten wat liefde is"
    },
    {
        "name": "Drummer",
        "artist": "Samson & Gert",
        "youtubeId": "sKfmPPM1SBo",
        "name_l": "drummer"
    },
    {
        "name": "De Canon",
        "artist": "Samson & Gert",
        "youtubeId": "UkKHVQyabRU",
        "name_l": "de canon"
    },
    {
        "name": "Honolulu",
        "artist": "Samson & Gert",
        "youtubeId": "s5lvujfQrrE",
        "name_l": "honolulu"
    }
];
