import styled from "styled-components";

export const Container = styled.div<{
  active: boolean;
  isCorrect: boolean | undefined;
}>`
  width: 100%;
  height: 45px;

  margin: 5px auto;

  display: flex;
  align-items: center;

  border-color: green;
  border-width: 0;
  border-radius: 0;
  border-style: solid;

  color: ${({ theme }) => theme.text};
`;

export const Text = styled.p`
  width: 100%;
  height: max-content;
  font-family: 'samson';
  letter-spacing: 1px;

  padding: 0px 10px;

  font-size: 1.2rem;
  color: ${({ theme }) => theme.text};
`;

export const Paw = styled.img`
  padding-right: 0.5em;
  width: auto;
  height: 100%;
`;