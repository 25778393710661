import React from "react";

import { Song } from "../../types/song";
import { GuessType } from "../../types/guess";
import { scoreToEmoji } from "../../helpers";

import { Button } from "../Button";
import { YouTube } from "../YouTube";

import * as Styled from "./index.styled";

interface Props {
  didGuess: boolean;
  currentTry: number;
  todaysSolution: Song;
  guesses: GuessType[];
}

export function Result({
  didGuess,
  todaysSolution,
  guesses,
  currentTry,
}: Props) {
  const hoursToNextDay = Math.floor(
    (new Date(new Date().setHours(24, 0, 0, 0)).getTime() -
      new Date().getTime()) /
      1000 /
      60 /
      60
  );

  const textForTry = ["Mwa seg hé!", "Proficiat!", "Goed zo!", "Puik werk!", "Prima!", "Dat was nipt!"];

  if (didGuess) {
    const copyResult = React.useCallback(() => {
      navigator.clipboard.writeText(scoreToEmoji(guesses));
    }, [guesses]);

    const triesConjugation = currentTry === 1 ? "poging" : "pogingen";

    return (
      <>
        <Styled.ResultTitle>{textForTry[currentTry - 1]}</Styled.ResultTitle>
        <Styled.SongPrefix>
          De intro van vandaag is:
        </Styled.SongPrefix>
        <Styled.SongTitle>
          {todaysSolution.name}
        </Styled.SongTitle>
        <Styled.Tries>
          Je had {currentTry} {triesConjugation} nodig.
        </Styled.Tries>
        <YouTube id={todaysSolution.youtubeId} />
        <Button onClick={copyResult} variant="green">
          Kopieer resultaat
        </Button>
        <Styled.TimeToNext>
          Kom morgen terug voor een nieuwe opgave. Nog {hoursToNextDay}{" "}
          uur wachten!
        </Styled.TimeToNext>
      </>
    );
  } else {
    return (
      <>
        <Styled.ResultTitle>Lap zeg! Mislukt!</Styled.ResultTitle>
        <Styled.SongPrefix>
          De intro van vandaag was:
        </Styled.SongPrefix>
        <Styled.SongTitle>
          {todaysSolution.name}
        </Styled.SongTitle>
        <YouTube id={todaysSolution.youtubeId} />
        <Styled.TimeToNext>
          Probeer het morgen opnieuw. Nog {hoursToNextDay}{" "}
          uur wachten!
        </Styled.TimeToNext>
      </>
    );
  }
}
