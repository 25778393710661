import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin-bottom: 5px;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: space-between;

  width: 40%;

  text-align: center;

  @media (max-width: 768px) {
    width: 95%;
  }

  max-width: 650px;

  svg:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  a {
    color: ${({ theme }) => theme.text};
  }
`;

export const Logo = styled.h1`
  color: ${({ theme }) => theme.text};
  font-family: "samson", sans-serif;
  text-transform: uppercase;
  width: max-content;

  display: inline-block;

  text-align: center;

  -webkit-touch-callout: none;
  user-select: none;

  cursor: pointer;

  background-image: linear-gradient(180deg, rgba(255,254,54,1) 0%, rgba(236,64,116,1) 40%, rgba(255,254,54,1) 51%, rgba(236,64,116,1) 90%);
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;
