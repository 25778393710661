export const theme = {
  border: "#d0cd6b",
  border100: "#e2e1a5",

  text: "#FFFFFF",
  background100: "#7276D9",

  green: "#4DBB60",
  red: "#FF0000",
  gray: "#E6E6E6",
} as const;
