import React from "react";
import { IoMusicalNoteOutline, IoHelpCircleOutline } from "react-icons/io5";
import { Button } from "..";

import * as Styled from "./index.styled";

interface Props {
  onClose: () => void;
}

export function InfoPopUp({ onClose }: Props) {
  return (
    <Styled.Container>
      <Styled.PopUp>
        <h1>Dag persoon die ik niet ken! 👋</h1>
        <Styled.Spacer />
        <Styled.Section>
          <IoMusicalNoteOutline size={75} />
          <p>
            Op meerineenintro.be raad je elke dag een<br></br>
            nieuw Samson-nummer! Ben jij een echte Samson-kenner? Kom elke dag terug!
          </p>
        </Styled.Section>
        <Styled.Section>
          <IoHelpCircleOutline size={68} />
          <p>
            Raad welke Samson-intro je hoort.<br></br>
            Hoe sneller je het lied herkent, hoe beter! 
            Dat is toch geen plobreem?!
          </p>
        </Styled.Section>
        <Button variant="green" style={{ marginTop: 20 }} onClick={onClose}>
          Dat is één van mijn specialiteiten
        </Button>
        <Styled.Contact>
          Limonade en chocolade: {" "}
          <a href="mailto:ikmoestkloppen@meerineenintro.be">
            ikmoestkloppen@meerineenintro.be
          </a>{" "}
        </Styled.Contact>
      </Styled.PopUp>
    </Styled.Container>
  );
}
