import styled from "styled-components";

export const ResultTitle = styled.h1`
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`;

export const Tries = styled.h4`
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }

  margin-top: 0;
`;

export const SongTitle = styled.h3`
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }

  font-family: 'samson';
  font-size: 2em;
  margin-top: 0;
`;

export const SongPrefix = styled.h3`
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }

  margin-top: 0;
`;

export const TimeToNext = styled.h4`
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`;
